﻿$header: #252525;
$footer: #20232a;
$body: #292c34;
$links:#478ce9;
$Hover:#e73f3b;
$copy:#eee;
$nav:#fff;
$h1:#20232a;
$h2:#e73f3b;
$para:#20232a;
$aside:#eee;
$subNav:#363636;
$subNavLinks: #999999;
$subNavActive:#db5555;
$Marker:#e73f3b;
$subHeader:#252525;
$white:#fff;
$intro:#999;
$darkBlue:#0569d8;
$red:#e6233e;
$aside:#e6e6e6;
$tableHead: #bbbbbb;
$mustard: #d2b249;
$darkMustard: #bfa243;
$cream: #e6ca6b;
$darkNavy:#033952;
$pale-blue:#83c2e4;
$paleBlue:#91d0f2;
$yellow:#ffc823;
$crimson:#dc1f0a;
$green: #3fad8a;
$paleGreen: #70f9cd;
$plum: #c7a8e4;
$navy: #001d49;
$blue: #478ce9;
$audio: #ff0049;

// greys
$black: #252525;
$jetBlack: #000000;
$darkGrey: #444444;
$lightGrey:#ddd;
$extraLightGrey:#f6f5f5;
$grey:#444;
$midGrey:#888;
$G999:#999;

// sections
$about:#076c9b;
$approach: #1f9c9c;