﻿// Responsive for Home
body.home-page, 
body.interviews-page {
    @media (min-width: 375px) {

        .main-header,
        .about,
        .approach {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .main-header {
            .main-nav,
            .visitor-count,
            .toggle-music {
                display: none;
            }

            .logo {
                position: absolute;
                left: -3.25rem
            }

            .right-section {
                a {
                    .material-icons {
                        display: none;
                    }
                }
            }

            .sign-out {
                display: none;
            }

            .visitor-count {
                display: none;
            }

            .device {
                &.mobile {
                    display: block;
                    color: $red;
                }
            }
        }

        // SECTION HEIGHTS MUST EXCEED WINDOW FOR ANCHORS TO WORK PROPERLY
        .new-section {
            min-height: 58rem;

            &.home {
                padding-top: 30%;

                p {
                    display: none
                }

                p.intro-mobile {
                    display: block
                }
                .street {
                    margin-bottom: 5rem;
                    .train,
                    .tree,
                    .fast-train,
                    .seagull,
                    .garbage-truck,
                    .balloon,
                    .plane,
                    .sign,
                    .uni,
                    .brann,
                    .pfizer,
                    .discovery,
                    .bbc,
                    .turner,
                    .ibm,
                    .umg,
                    .anglo {
                        display: none;
                    }
                    .car {
                        top: 15rem;
                        width: 3rem;
                        height: 2rem;
                    }
                    .house {
                        width: 10rem;
                        height: 16rem;

                        &:hover {
                            .quote-of-the-day {
                                display: none;
                            }
                        }

                        .arch {
                            z-index: 50;
                            width: 18rem;
                            height: 21rem;
                            top: 0;
                            left: -4rem;
                        }
                    }
                }
            }

            &.about {
                .row {
                    margin-right: 0;
                    margin-left: 0;
                    padding-top: 0;

                    .col-sm-12 {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }

                .icons {
                    position: static;
                }
            }

            &.approach {
                .row {
                    margin-right: 0;
                    margin-left: 0;

                    .col-md-auto {
                        text-align: center
                    }

                    img {
                        margin: 0 auto 30px auto
                    }

                    div {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }

            &.projects {
                .projects-header {
                    nav {
                        > a {
                            display: none
                        }
                    }
                }
            }

            &.contact {
                margin-bottom: 0;

                h1 {
                    margin-top: 30%
                }

                img {
                    margin: 0% auto 0 auto;
                }

                ul {
                    margin: 20% 0 0 22%;

                    li {
                        float: left;
                        clear: left;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    @media (min-width: 576px) {

        .main-header {
            .logo {
                margin-right: 3rem;
                position: static;
            }
            .right-section {
                a {
                    .material-icons {
                        display: block;
                    }
                }
            }

            .sign-out {
                display: flex;
            }

            .device {
                &.mobile {
                    display: none;
                }
                &.tablet {
                    display: flex;
                    color: $green;
                }
            }
        }

        // SECTION HEIGHTS MUST EXCEED WINDOW FOR ANCHORS TO WORK PROPERLY
        .new-section {
            &.home {
                p {
                    display: block
                }

                p.intro-mobile {
                    display: none
                }

                .street {
                    .house {
                        width: 10rem;
                        height: 16rem;

                        &:hover {
                            .quote-of-the-day {
                                display: block;
                            }
                        }
                    }
                }
            }

            &.about {
                .row {
                    margin-right: 0;
                    margin-left: 0;
                    padding-top: 30px;
                }

                .icons {
                    bottom: 60px;

                    div {
                        margin: 0 6%;

                        p {
                            font-size: .62rem
                        }
                    }
                }
            }

            &.projects {
                .projects-header {
                    nav {
                        > a {
                            display: flex
                        }
                    }
                }
            }

            &.contact {
                h1 {
                    margin-top: 18%;
                }
                ul {
                    margin: 40px 0 0 0;

                    li {
                        float: none;
                        clear: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {

        .main-header {
            padding-left: 3rem;

            .logo {
                margin-right: 3rem
            }
        }

        .approach {
            padding-left: 3rem;
            padding-right: 3rem;
        }
        // compensate for row col spacing
        .about {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .main-header {
            //border-bottom: 3px solid lightblue;
            .device {
                &.tablet {
                    color: $red;
                }
            }
            .sign-out-panel {
                left: unset;
                right: 10.5rem;
            }
        }

        // SECTION HEIGHTS MUST EXCEED WINDOW FOR ANCHORS TO WORK PROPERLY
        .new-section {
            min-height: 84rem;

            &.home {
                padding-top: 20%;
            }

            &.about {
                .row {
                    .col-sm-12 {
                        padding-right: 1rem;
                        padding-left: 1rem;
                    }
                }

                .icons {
                    bottom: 60px;
                    position: absolute;
                    div {
                        margin: 0 5%;

                        p {
                            font-size: .62rem
                        }
                    }
                }
            }

            &.projects {
                .projects-header {
                    nav {
                        >a {
                            display: inline-block
                        }
                    }
                }
            }

            &.contact {
                h1 {
                    margin-top: 40%
                }

                ul {
                    margin: 40px 0 0 0;

                    li {
                        float: none;
                        clear: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {

        .main-header {
            padding-left: 3rem;
            .main-nav,
            .visitor-count,
            .toggle-music {
                display: block;
            }
            .logo {
                margin-right: 0
            }
        }

        .approach {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }
        // compensate for row col spacing
        .about {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        .main-header {
            .device {
                &.tablet {
                    color: $blue;
                }
            }

            .main-nav-small {
                display: none;
            }

        }

        // SECTION HEIGHTS MUST EXCEED WINDOW FOR ANCHORS TO WORK PROPERLY
        .new-section {
            min-height: 85rem;

            &.home {
                padding-top: 15%;
            }

            &.about {
                .row {
                    .col-sm-12 {
                        padding-right: 1rem;
                        padding-left: 1rem;
                    }
                }

                .icons {
                    bottom: 90px;
                    p {
                        font-size: .62rem
                    }
                }
            }

            &.approach {
                .row {
                    .col-md-auto {
                        text-align: left
                    }

                    img {
                        margin: 0
                    }

                    div {
                        padding-right: 1rem;
                        padding-left: 1rem;
                    }
                }
            }

            &.projects {
                .projects-header {
                    nav {
                        >a {
                            display: inline-block
                        }
                    }
                }
            }

            &.contact {
                h1 {
                    margin-top: 18%
                }

                ul {
                    margin: 40px 0 0 0;

                    li {
                        float: none;
                        clear: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (min-width: 1280px) {

        .about {
            padding-left: 15%;
            padding-right: 15%;
        }
        
        .main-header {
            .logo {
                margin-right: 0
            }
            .device {
                &.tablet {
                    display: none;
                }
                &.desktop {
                    display: block;
                    color: $red;
                }
            }
        }

        // SECTION HEIGHTS MUST EXCEED WINDOW FOR ANCHORS TO WORK PROPERLY
        .new-section {
            //background-color: lightblue;
            min-height: 79rem;

            &.home {
                padding-top: 10%;

                .street {
                    margin: 8rem 0 3rem;
                    .car,
                    .tree,
                    .train,
                    .fast-train,
                    .seagull,
                    .garbage-truck,
                    .balloon,
                    .plane,
                    .sign,
                    .uni,
                    .brann,
                    .pfizer,
                    .discovery,
                    .bbc,
                    .turner,
                    .ibm,
                    .umg,
                    .anglo {
                        display: inline-block;
                    }

                    .car {
                        top: 8.45rem;
                        width: 1.5rem;
                        height: 1rem;
                    }

                    .house {
                        width: 4rem;
                        height: 7rem;
                        margin-right: 2rem;

                        .arch {
                            width: 8rem;
                            height: 12rem;
                            top: -3rem;
                            left: -2rem;
                        }
                    }
                }
            }

            &.about {
                .row {
                    .col-sm-12 {
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;
                    }
                }

                .icons {
                    bottom: 75px;

                    p {
                        font-size: .62rem
                    }
                }
            }

            &.projects {
                .projects-header {
                    nav {
                        >a {
                            display: inline-block
                        }
                    }
                }
            }

            &.contact {
                h1 {
                    margin-top: 12%
                }

                ul {
                    margin: 40px 0 0 0;

                    li {
                        float: none;
                        clear: none;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (min-width: 2000px) {
        .main-header {
            .logo {
                margin-right: 0
            }
            .device {
                &.desktop {
                    color: $black;
                }
            }
        }

        // SECTION HEIGHTS MUST EXCEED WINDOW FOR ANCHORS TO WORK PROPERLY
        .new-section {
            //background-color: pink;
            min-height: 79rem;

            &.home {
                padding-top: 10%;

                .street {
                    margin: 6rem 0 5rem 0;
                }
            }

            &.about {
                .row {
                    padding-top: 50px;
                }
                .icons {
                    bottom: 160px;
                }
            }

            &.approach {
                .row {
                    img {
                        width: 115px;
                    }
                }
            }
        }
    }
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------- PROJECTS ----------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
html {
    body.projects {
        @media (min-width: 375px) {

            .transpose-menu {
                .main-header {
                    background-image: none;
                }
            }

            .main-header {
                padding: 0 1.75rem;
                background-position: top .42rem center;

                .logo {
                    position: absolute;
                    left: -3.5rem;
                }

                .back-link {
                    span {
                        &:last-child {
                            display: none;
                        }
                    }
                }

                .right-section {
                    margin-right: 0;

                    a {
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }

            .container {
                a.top {
                    display: none
                }
            }

            // tweak banner image positioning for mobile
            .banner.umg-active img {
                max-width: 150%;
                top: 26%;
                left: -14%;
            }

            .banner.turner-active img {
                max-width: 125%;
                top: 7%;
                left: -13%;
            }

            .banner.monum-active img {
                max-width: 130%;
                left: -22%;
                bottom: 0;
            }

            .banner.setanta-active img {
                max-width: 150%;
                top: 12%;
                left: -14%;
            }

            .banner {
                height: 190px;

                nav.downloads-menu {
                    display: none
                }

                nav.projects-menu {
                    top: 112px;
                    left: 2rem;
                    right: 0;

                    h4 {
                        display: none
                    }

                    ul li {
                        width: 33%;
                        display: inline-block;
                    }
                }

                h1 {
                    margin: 70px 0 6px 0;
                    font-size: 1.1rem;
                }
            }

            .projects-header {
                bottom: 75px !important;

                ul {
                    display: none
                }
            }

            .container-fluid {
                margin-top: 190px;

                //border: 2px solid red;
                h2 {
                    font-size: 40px;
                }

                .row {

                    > * {
                        padding-right: 2rem;
                        padding-left: 2rem;
                    }

                    .red-panel {
                        margin-top: 20px;
                    }

                    img.batman {
                        width: 185%;
                        margin-top: 0;
                        position: static;
                        margin-bottom: 20px;
                    }
                }

                .row.carousel {
                    margin: 30px 0 -125px 0;

                    img {
                        margin-top: 0 !important;
                    }
                }

                .row.scooby {
                    padding-top: 60px;
                    padding-bottom: 50px;

                    >div:first-child {
                        margin-right: 130px;
                    }

                    img.scooby-van {
                        top: -172px;
                        width: 190px;
                    }

                    .para1 {
                        margin-left: 0;
                        padding-left: 0;
                        margin-top: 50px;
                    }

                    .para2 {
                        padding-left: 0;
                        margin-top: 0;
                    }
                }

                .row.ctn-ntw {
                    padding-top: 80px;

                    img.adv-time {
                        top: -103px;
                        right: 5%;
                        width: 161px;
                    }
                }

                .row.youtube {
                    ul {
                        display: none
                    }
                }
            }

            img.personas {
                width: 400px;
                margin-top: -79px
            }
        }

        @media (min-width: 576px) {

            .main-header {
                .logo {
                    position: static;
                }
                .right-section {
                    a {
                        &:last-child {
                            display: flex;
                        }
                    }
                }

                .back-link {
                    span {
                        &:last-child {
                            display: flex
                        }
                    }
                }
            }

            .container {
                a.top {
                    display: none
                }
            }

            .banner,
            .banner.umg-active,
            .banner.turner-active,
            .banner.setanta-active {
                img {
                    max-width: 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: -1;
                }
            }

            .banner.monum-active {
                img {
                    max-width: 100%;
                    bottom: 63px;
                    top: auto;
                    left: 0;
                }
            }

            .banner {
                height: 395px;

                nav.downloads-menu {
                    display: block
                }

                nav.projects-menu {
                    top: 4.5rem;
                    left: 60px;
                    right: auto;

                    h4 {
                        display: block
                    }

                    ul li {
                        width: auto;
                        display: block;
                    }
                }

                h1 {
                    margin: 70px 0 105px 0;
                    font-size: 1.5rem;
                }
            }

            .projects-header {
                bottom: 25px !important;

                ul {
                    display: block
                }
            }

            .container-fluid {
                padding-right: 25px;
                padding-left: 25px;
                margin-top: 395px;

                //border: 2px solid yellow;
                h2 {
                    font-size: 3rem;
                }

                .row {
                    .red-panel {
                        margin-top: 20px;
                    }

                    img.batman {
                        width: 108%;
                        margin-top: 0;
                        position: static;
                        margin-bottom: 0;
                    }
                }

                .row.carousel {
                    margin: 30px 0 -160px 0;

                    img {
                        margin-top: -10px !important;
                        ;
                    }
                }

                .row.scooby {
                    padding-top: 60px;
                    padding-bottom: 100px;

                    >div:first-child {
                        margin-right: 130px;
                    }

                    img.scooby-van {
                        top: -172px;
                        width: 400px;
                    }

                    .para1 {
                        margin-left: 0;
                        max-width: 90%;
                        padding-left: 60px;
                        margin-top: 250px;
                    }

                    .para2 {
                        max-width: 90%;
                        padding-left: 60px;
                        margin-top: 0;
                    }
                }

                .row.ctn-ntw {
                    padding-top: 80px;

                    img.adv-time {
                        top: -169px;
                        right: 113px;
                        width: 265px;
                    }
                }

                .row.youtube {
                    ul {
                        display: none
                    }
                }

                .projects-header ul {
                    display: block;
                    margin: 0 35px
                }
            }

            img.personas {
                width: 600px;
                margin-top: -91px
            }
        }

        @media (min-width: 768px) {

            .container-fluid {
                //border: 2px solid green;
                .row {
                    .red-panel {
                        margin-top: 20px;
                    }

                    img.batman {
                        width: 108%;
                        margin-top: 0;
                        position: static;
                    }
                }

                .row.scooby {
                    padding-top: 60px;
                    padding-bottom: 180px;

                    >div:first-child {
                        margin-right: 130px;
                    }

                    .para1 {
                        margin-left: 0;
                        max-width: 90%;
                        padding-left: 60px;
                        margin-top: 300px;
                    }

                    .para2 {
                        max-width: 90%;
                        padding-left: 60px;
                        margin-top: 0;
                    }

                    img.scooby-van {
                        top: -172px;
                    }
                }

                .row.ctn-ntw {
                    padding-top: 80px;

                    img.adv-time {
                        top: -148px;
                        right: 47px;
                        width: 228px;
                    }
                }

                .row.youtube {
                    ul {
                        display: block
                    }
                }

                .projects-header ul {
                    display: block;
                    margin: 0 60px
                }
            }

            img.personas {
                width: 100%;
                margin-top: -90px
            }
        }

        @media (min-width: 992px) {
            .container {
                a.top {
                    display: inline-block
                }
            }

            .container-fluid {

                //border: 2px solid orange;
                .row {
                    .red-panel {
                        margin-top: 0;
                    }

                    img.batman {
                        width: 180%;
                        margin-top: 50px;
                        position: absolute;
                    }
                }

                .row.scooby {
                    padding-top: 60px;
                    padding-bottom: 130px;

                    >div:first-child {
                        margin-right: 130px;
                    }

                    img.scooby-van {
                        top: -172px;
                    }

                    .para1 {
                        margin-left: 415px;
                        max-width: 44%;
                        min-width: 50%;
                        padding-top: 30px;
                        margin-top: 0;
                    }

                    .para2 {
                        max-width: 90%;
                        padding-left: 60px;
                        margin-top: 40px;
                        margin-bottom: 0;
                        min-width: 90%;
                    }
                }

                .row.ctn-ntw {
                    img.adv-time {
                        top: -200px;
                        right: 65px;
                        width: 312px;
                    }
                }

                .row.youtube {
                    ul {
                        display: block
                    }
                }

                .projects-header ul {
                    display: block;
                    margin: 0 60px
                }
            }

            img.personas {
                width: 100%;
                margin-top: -160px
            }
        }

        @media (min-width: 1580px) {
            .container-fluid {
                .row {
                    img.batman {
                        width: 170%;
                        margin-top: -96px;
                        position: absolute;
                    }
                }

                .row.scooby {
                    padding-top: 100px;
                    padding-bottom: 0;

                    >div {
                        margin-right: 30px
                    }

                    >div:first-child {
                        margin-right: 0;
                    }

                    img.scooby-van {
                        top: -230px;
                    }

                    .para1 {
                        margin-left: 0;
                        max-width: 100%;
                        min-width: initial;
                        padding-top: 0;
                        padding-left: 0;
                    }

                    .para2 {
                        max-width: 100%;
                        padding-left: 0;
                        margin-top: 0;
                        min-width: initial;
                    }
                }

                .row.ctn-ntw {
                    img.adv-time {
                        top: -216px;
                        right: 152px;
                        width: 335px;
                    }
                }

                .row.youtube {
                    ul {
                        display: block
                    }
                }

                .projects-header ul {
                    display: block;
                    margin: 0 60px
                }
            }
        }
    }
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------- BLOG ------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------
html {
    body.blog {
        @media screen and (min-width: 800px) and (max-width: 900px) {
            .top-section {
                padding: 1rem 3.5rem 0!important;
                h1 {
                    font-size: 2rem!important;
                    margin-bottom: 0!important
                }
            }
            main {
                margin-top: 10rem!important;
                height: calc(100vh - 10rem) !important;
            }

            footer {
                display: none!important;
            }
        }

        @media (min-width: 375px) {
            .main-header {
                padding: 0 1.5rem;
                a {
                    span:not(.material-icons) {
                        display: none;
                    }
                }

                .sign-out {
                    .material-icons {
                        display: none;
                    }
                }
            }

            main {
                padding: 0 2rem;
                margin-top: 9rem;
                height: calc(100vh - 25rem);
            }

            article {
                &:first-child {
                    padding-top: 1rem;
                }
            }


            .top-section {
                padding: 1rem 2rem 0;
                h1 {
                    font-size: 2rem;
                }
            }

            .tree,
            .grass,
            .tunnel,
            .bench {
                display: none;
            }

            .footer {
                height: 15.5rem;
                padding: 2rem 2rem 0;
                
                .house {
                    position: absolute;
                    top: 2rem;
                    left: 2rem;
                    right: 2rem;
                    bottom: 0;
                    width: 100%;
                    background-position: right 3rem bottom;
                }
            }
        }

        @media (min-width: 768px) {
            .main-header {
                padding: 0 11.25rem 0 3.5rem;

                a {
                    span:not(.material-icons) {
                        display: block;
                    }
                }

                .sign-out {
                    .material-icons {
                        display: block;
                    }
                }
            }

            main {
                padding: 0 3.5rem 0;
                margin-top: 12rem;
                height: calc(100vh - 37rem);
            }

            article {
                &:first-child {
                    padding-top: 2rem;
                }
            }

            .top-section {
                padding: 2rem 3.5rem 0;
                h1 {
                    font-size: 3.5rem;
                }
            }

            .tree {
                width: 22rem!important
            }

            .grass {
                width: 10rem!important;
                margin-right: -3rem!important
            }

            .tree,
            .grass,
            .bench {
                display: flex;
            }

            h1 {
                font-size: 2rem;
                line-height: 4rem;
            }

            .footer {
                height: 25rem;
                padding: 3rem 3.5rem 0;

                .house {
                    position: static;
                    background-position: center bottom;
                    width: 10rem
                }
            }
        }

        @media (min-width: 992px) {
            .main-header {
                padding: 0 11.25rem 0 3.5rem;

                a {
                    span:not(.material-icons) {
                        display: block;
                    }
                }

                .sign-out {
                    .material-icons {
                        display: block;
                    }
                }
            }

            main {
                height: calc(100vh - 32rem);
            }

            .tree {
                display: flex;
                width: 23rem!important
            }

            .grass {
                width: 15rem!important
            }

            .top-section {
                padding: 2rem 3.5rem 0;
            }

            .tunnel {
                display: none;
            }

            h1 {
                font-size: 2rem;
                line-height: 4rem;
            }

            .footer {
                height: 20rem;
                padding: 3rem 3.5rem 0;

                .house {
                    position: static;
                    background-position: center bottom;
                    width: 10rem;
                    background-size: 9rem;
                }
            }
        }

        @media (min-width: 1580px) {
            .tunnel {
                display: flex;
            }
        }
    }
}