.interviews-nav-section {
    position: fixed;
    background-color: $white;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 6rem 0 0 4rem;
    width: 22rem;
    box-shadow: 0.5rem 0 .75rem rgba($jetBlack, 0.03);
    border-right: 1px solid $lightGrey;

    a {
        @include font(300, .63rem, 'latobold');
        text-transform: uppercase;
    }

    nav.interviews-nav {
        white-space: nowrap;
        a {
            height: 17px;
            margin: .75rem 2rem 0 0;
            padding: 0;
            cursor:pointer;
            color:$black;
            display: block;
            width: 100%;
            font-size: .75rem;
        }
        a:hover {text-decoration: none!important; color: $red!important}
        a.active {
            opacity:1;
            border-left: 4px solid $red;
            padding-left: .75rem;
        }
        h4 {
            font-size: 1rem;
            color: $red;
        }
    }
}

.interviews-content {
    .interviews-header {
        background-color: $paleGreen;
        h4 {
            margin: 0 1rem 0 1rem;
        }

        .divider {
            width: .1rem;
            height: 1rem;
            margin: 0;
            background-color: $green;
        }

        a {
            padding: .15rem 0;
            margin: 0 1rem;
            &.active {
                border-bottom: 2px solid red;
            }
        }
    }

    .element {
        border-bottom: 2px dotted $red;
        &:nth-child(odd) {
            background-color: $extraLightGrey;
        }
    }

    h2, h3 {color: $red;}
    h3 {margin: 1rem 0 0 0}
    h2 {
        font-size: 2rem;
    }
    strong {margin-top: .75rem;}
    .script {
        min-height: 100vh;
        font-size: 1.1rem;
        line-height: 1.75rem;
        text-align: center;
        font-style: italic;

        p {
           max-width: 45rem;
           border-bottom: 1px dashed $red;
           padding: .75rem 0;
           margin: 0;
           width: 100%;

           &:last-child {
            border-bottom: none;
           }

           &.example {
            color: $red
           }
        }
    }
}
