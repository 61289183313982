﻿@import "mixins";

html {
    height: 100%;
    body {
        font-family: 'latoregular'; // everything text based apart from HTags, Strong and Italics
        font-size: .75rem;
        letter-spacing: 0.05em;
        line-height: 1.5rem;
        margin: 0; 
        padding:0;
        // lighter font for dark backgrounds looks better visually
        section.new-section.about,
        section.new-section.approach {
            font-family: 'latolight';
            font-weight: bolder;
        }
        strong {font-family: 'latoblack'; font-size: .9rem;}
        i {font-family: 'latoitalic';}
        link, a, a:visited {color:$red; text-decoration:none; cursor:pointer}
        a:hover {text-decoration:none; color:$Hover}
        ul {
            li {padding-left:8px}
        }
        pre {
            white-space: pre-line;
        }
        p, pre {
            margin:0 0 20px 0;
            font-family: 'latoregular';
            font-weight: normal;
            a {font-size: .75rem}
        }
        h1 {
            color:$black; 
            text-align: center; 
            margin:0 0 5px 0;
            padding:30px 0 0 0;
            font-family: 'oraqlescript';
            font-size: 3.5rem;
        }
        h2 {
            margin:0 0 15px 0;
            font-family: 'latoregular';
            font-size: 25px;
            clear:left;
        }
        h3 {
            color:$black;
            font-family: 'latoregular';
            font-size: 1.1rem;
            margin: 0 0 3px 0;
        }
        h4 {
            font-family: 'latoregular';
            color:$darkNavy;
            text-transform: uppercase;
            font-size:.8rem;
            font-weight: bolder;
            margin-bottom: .2rem;
        }
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }
        figure:hover { 
            a {opacity:1;} 
            .tint {
                @include transition(all 0.2s ease-in);
                opacity:0.6;
            }
        }
        figure {
            margin: 0;
            position: relative;
            overflow: hidden;
            .tint {
                @include transition(all 0.2s ease-in);
                position: absolute;
                background:$jetBlack;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            a {  
                color:$white;
                text-transform: uppercase;
                position: absolute;
                top: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                text-align: center;
                opacity: 0;
                margin: 0;
                padding-top: 25.5%;
                border:none;
                h3 {
                    color:$white;
                    letter-spacing: .2rem;
                    font-family: 'latoregular';
                    font-size: .85rem;
                    line-height: 1.25rem;
                }
                p {
                    letter-spacing: .2rem;
                    line-height: 1.3rem;
                    font-family:'latolight';
                    font-size: .65rem;
                    color:$white;
                }
            }
        }
    
        .page-loading {
            width: 100%;
            height: 66vh;
    
            p {
                width: 14rem;
                margin: 0;
                text-align: left;
                line-height: 1.25rem;
                font-size: .7rem;
            }
    
            img {
                &.px-logo {
                    width: 12rem;
                    margin-bottom: .5rem;
                }
    
                &.anim {
                    width: 10rem
                }
            }
        }

        .unauthorized {
            padding-top: 10rem
        }
    }    
}

// Scrollbars

::-webkit-scrollbar {
    width: 15px!important;
    height: 15px!important;
}

::-webkit-scrollbar-thumb {
    border: .35rem solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: $red;
}

::-webkit-scrollbar-button {
    display: none;
}