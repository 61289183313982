﻿.row {
    width:100%;
    .col {
        display:inline-block;
    }
}

.page-fade-in:not(body.disable-transitions .page-fade-in) {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}
  
.page-fade-in.visible:not(body.disable-transitions .page-fade-in) {
    opacity: 1;
}

.zoom-to-project:not(body.disable-transitions .page-fade-in) {
    transition: transform 2s cubic-bezier(0.42, 0, 1, 1), opacity 2s cubic-bezier(0.42, 0, 1, 1);
    transform-origin: center;
    opacity: 0;
}

section.new-section {
    position: relative;
    width:100%;
    display:inline-block;
    margin: 0;
    scroll-margin-top: 2.75rem;

    &.home {
        text-align: center;
        overflow: hidden;
        .street {
            position: relative;
            width: 100%;
            text-transform: uppercase;

            .pennant-flag {
                position: absolute;
                width: 2.5rem;
                height: 1.75rem;
                right: 100%;
                top: 0;
                transform: scaleX(-1);
            }

            .right .pennant-flag {
                transform: scaleX(1);
                right: auto;
                left: 100%;
            }
            
            img {
                margin-bottom: .5rem;;
                &.logo-ravensbourne {width: 1.75rem}
                &.logo-brann {width: 3.5rem}
                &.logo-pfizer {width: 2.5rem}
                &.logo-discovery {width: 3.25rem}
                &.logo-bbc {width: 3rem}
                &.logo-turner {width: 4.5rem}
                &.logo-ibm {width: 2.5rem}
                &.logo-universal {width: 4rem}
                &.logo-anglo {width: 6rem}
            }

            h4 {
                font-size: .7rem;
                padding-bottom: .25rem;
            }

            a {
                &:hover {
                    strong {border-bottom: 1px solid $Hover;}
                }
            }
            
            a, div {
                display: inline-block;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: bottom center;
                text-align: left;

                .hover {
                    position: relative;
                    bottom: 0;
                    z-index: 999999;
                    width:100%;
                    height: 100%;
                    strong {margin-top: .5rem}
                    &:hover + {
                        article {
                            opacity: 1;
                        }
                        strong {
                            opacity: .7;
                        }
                    }
                }
                &.show-job-desc {
                    article {
                        opacity: 1;
                        border-left: 1px dashed $Hover;
                        padding-left: .5rem;

                        &.right {
                            text-align: right;
                            border-left: none;
                            padding-left: 0;
                            padding-right: .5rem;
                            border-right: 1px dashed $Hover;
                        }
                    }
                    strong {
                        opacity: .8;
                    }
                }
                strong, article {
                    position: absolute;
                    text-align: left;
                    font-size: .5rem;
                }
                strong {
                    top: 100%;
                    line-height: .75rem;
                    opacity: .55;
                }
                article {
                    line-height: .8rem;
                    top: -3rem;
                    opacity: 0;
                    color: $Hover;
                    width: 12rem;
                    .marker {
                        background-color: $red;
                        width: .2rem;
                        height: 2rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                &.charing-cross {
                    position: absolute;
                    background: url("../images/home/nat-rail.png") center top no-repeat;
                    background-size: 100%;
                    width: 1.25rem;
                    height: 4em;
                    top: 5.75rem;
                    margin-left: 55.6rem;
                    z-index: 120;
                    overflow: hidden;
                }

                &.tree {
                    position: absolute;
                    background-image: url("../images/home/tree.png");
                    width: 20rem;
                    height: 20rem;
                    top: -6rem;
                    margin-left: 57rem;
                    z-index: 120;
                }

                &.car.reverse-direction {
                    &.car1 {background-image: url(../images/home/car1r.png)!important;}
                    &.car2 {background-image: url(../images/home/car2r.png)!important;}
                    &.car3 {background-image: url(../images/home/car3r.png)!important;}
                    &.car4 {background-image: url(../images/home/car4r.png)!important;}
                }

                &.seagull {
                    background-image: url("../images/home/seagull.gif");
                    position: absolute;
                    width: 5rem;
                    height: 5rem;
                    animation: bird 50s ease-in infinite;
                }
                &.balloon {
                    width: 5rem;
                    height: 9rem;
                    position: absolute;
                    z-index: 0;
                    animation: balloonWrapper 95s infinite ease-in-out alternate;
                    div.balloon-inner {
                        background-image: url("../images/home/balloon.png");
                        opacity: .9;
                        width: 100%;
                        height: 100%;
                        z-index: 10;
                        animation: balloon 6s infinite ease-in-out alternate;
                        transform-origin: 50% 100%;
                    }
                    article {
                        border: none;
                        margin: 0 0 0 5rem;
                    }
                }

                &.plane {
                    // move left
                    position: absolute;
                    z-index: 0;
                    animation: planeWrapper 100s infinite;
                    div {
                        // tilt slightly
                        position: absolute;
                        animation: planeTilt 12s infinite ease-in-out;
                        div {
                            // shift up & down
                            background-image: url("../images/home/plane.gif");
                            width: 6rem;
                            height: 3rem;
                            animation: plane 50s ease-in-out infinite;
                        }
                    }
                }
                &.garbage-truck {
                    background-image: url("../images/home/dustcart.png");
                    width: 3rem;
                    height: 1.5rem;
                    position: absolute;
                    z-index: 40;
                    bottom: .5rem;
                    animation: garbade 120s infinite;
                    transform: scaleX(-1);
                }
                &.train {
                    background-image: url("../images/home/train.png");
                    width: 19rem;
                    height: 2rem;
                    position: absolute;
                    z-index: 40;
                    bottom: .5rem;
                    animation: train-move 35s infinite;
                }
                &.fast-train {
                    background-image: url("../images/home/train.png");
                    width: 19rem;
                    height: 2rem;
                    position: absolute;
                    z-index: 40;
                    bottom: .5rem;
                    animation: train-move-fast 20s linear infinite;
                }
                &.line {
                    border-bottom: 1px solid $jetBlack;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: .5rem;
                }

                &.house {
                    background-image: url("../images/home/home.png");
                    position: relative;
                    cursor: pointer;
                    .arch {
                        position: absolute;
                        background-image: url("../images/home/arch.png");
                        // see responsive.scss for sizing
                    }

                    .quote-of-the-day {
                        display: none;
                        position: absolute;
                        font-family: 'latoregular';
                        text-transform: uppercase;
                        line-height: .8rem;
                        font-size: .5rem;
                        color: $red;
                        width: 13rem;
                        bottom: 9rem;
                        left: -1.25rem
                    }
                }
                &.sign {
                    background-image: url("../images/home/sign.png");
                    width: 12rem;
                    height: 14rem;
                    position: absolute;
                    z-index: 60;
                    top: -.5rem;
                    margin-left: 4rem;
                }
                &.uni {
                    background-image: url("../images/home/uni.png");
                    width: 9rem;
                    height: 4rem;
                    z-index: 30;
                    strong {margin-left: .7rem;}
                    article {
                        margin:-4rem 0 0 4.5rem; 
                        height: 131%; 
                        width: 15rem;
                        .marker {

                        }
                    }
                }
                &.brann {
                    background-image: url("../images/home/brann.png");
                    width: 7rem;
                    height: 3rem;
                    z-index: 30;
                    strong {margin-left: 1.75rem;}
                    article {width: 13rem; margin: -4rem 0 0 3rem; height: 144%;}
                }
                &.pfizer {
                    background-image: url("../images/home/pfizer.png");
                    width: 5rem;
                    height: 4rem;
                    strong {margin-left: 2.15rem;}
                    article {width: 12rem; margin: -2rem 0 0 1.6rem; height: 111%;}

                }
                &.discovery {
                    background-image: url("../images/home/discovery.png");
                    width: 6rem;
                    height: 4rem;
                    strong {margin-left: .5rem;}
                    article {width: 12rem; margin: -2rem 0 0 4.6rem; height: 108%;}
                }
                &.bbc {
                    background-image: url("../images/home/bbc.png");
                    width:10rem;
                    height: 4.25rem;
                    strong {margin-left: 2.5rem;}
                    article {width: 14rem; margin: -2rem 0 0 -9rem; height: 110%;}
                }
                &.turner {
                    background-image: url("../images/home/turner.png");
                    width: 6rem;
                    height: 6rem;
                    strong {margin-left: 1.35rem;}
                    article {width: 14rem; margin: -3rem 0 0 -10.45rem; height: 97%;}
                }
                &.ibm {
                    background-image: url("../images/home/tna.png");
                    width: 8rem;
                    height: 6.5rem;
                    strong {margin-left: 3.5rem;}
                    article {width: 15rem; margin: -4rem 0 0 -10.76rem; height: 102%;}
                }
                &.umg {
                    background-image: url("../images/home/umg.png");
                    width: 2.25rem;
                    height: 9rem;
                    strong {margin-left: .55rem;}
                    article {width: 16rem; margin: -5rem 0 0 -14.8rem; height: 93%;}
                }
                &.anglo {
                    background-image: url("../images/home/anglo.png");
                    width: 6rem;
                    height: 5.75rem;
                    strong {margin-left: .5rem;}
                    article {width: 18.25rem; margin: -6rem 0 0 -14.7rem; height: 133%;}
                }
            }
        }
        img.mike {
            position: relative;
            width: 100%;
            max-width: 530px;
            animation-duration: 1s;
            animation-timing-function: ease-in; /* how the animation will behave */
            animation-delay: 0s; /* how long to delay the animation from starting */
            animation-iteration-count: 1; /* how many times the animation will play */
            animation-name: zoomFadeIn; /* the name of the animation we defined above */
        }
        h1 {
            position: relative;
            z-index: 1;
            font-size: 6rem!important;
            width:100%;
            margin:0;
            padding:0;
            span.ellipsis {
                color:$red;
                letter-spacing: .2rem;
                padding-left: 8px;
            }
        }
        p {
            text-align: center;
            display: inline-block;
            margin: 0 auto;
        }
        .social {
            width: 100%;
            margin: 1rem 0 0 0;
            padding-right: 1rem;
            white-space: nowrap;
            a:hover {
                -webkit-animation: swing 1s ease;
                animation: swing 1s ease;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
            a {
                width: 1.6rem;
                height: 1.6rem;
                margin: 0 .5rem;
                img {width: 1.6rem; height: 1.6rem;}
            }
        }

        @keyframes balloonWrapper {
            /* 0% - Balloon starts at home, slightly larger to appear closer, and held for the first 10% */
            0% { transform: translatex(-8rem) translatey(-1.1rem) scale(1.3); opacity: .9; }
            /* 10% - Balloon stays in place, simulating a pause before takeoff */
            10% { transform: translatex(-8rem) translatey(-1.1rem) scale(1.3); opacity: .9; }
            
            /* 25% - Balloon takes off, rising up and moving slightly right */
            20% { transform: translatex(.75rem) translatey(-14rem) scale(1.2); opacity: .8; }

            /* 75% - Balloon is midway to work, scaling down further and fading as it moves further away */
            85% { transform: translatex(59rem) translatey(-7rem) scale(.4); opacity: .6; }

            /* 90% - Balloon lands at work, almost at its smallest size */
            95% { transform: translatex(59.5rem) translatey(-1.5rem) scale(.4); opacity: .5; }

             /* 100% - Balloon remains at work, holding its position after landing */
            100% { transform: translatex(59.5rem) translatey(-1.5rem) scale(.4); opacity: .5; }
        }
        
        @keyframes balloon {
            from {
                transform: rotate(-4deg);
            }
            to {
                transform: rotate(4deg);
            }
        }

        @keyframes planeWrapper {
            0% { right: -40% }
            100% { right: 150%; }
        }

        @keyframes plane {
            0% { transform: translateY(-5.5rem); }
            10% { transform: translateY(-5.3rem); } /* Slightly reduced movement */
            30% { transform: translateY(-6.5rem); } /* Reduced maximum bobbing height */
            50% { transform: translateY(-4rem); }  /* Reduced upward movement */
            70% { transform: translateY(-5rem); }  /* Slight downward movement */
            90% { transform: translateY(-4.5rem); } /* Reduced final bobbing down */
            100% { transform: translateY(-5.5rem); } /* Return to initial position */
          }

        @keyframes planeTilt {
            0% { transform: rotate(0deg); }
            50% { transform: rotate(10deg); }
            100% { transform: rotate(0deg); }
        }

        @keyframes train-move {
            0% { left: -20%; }           /* Start off-screen to the left */
            // DELAYED AT 50% POINT ----------------------------------------------------------
            35% { left: 60%; }           /* Hold at the center */
            65% { left: 60%; }           /* Accelerate back towards the right */
            // DELAYED AT 50% POINT ----------------------------------------------------------
            100% { left: 130%; }         /* Move off-screen to the right */
        }

        @keyframes train-move-fast {
            0% { right: -300%; }
            100% { right: 300%; }
        }

        @keyframes garbade {
            0% { left: 300%; }           /* Start off-screen to the left */
            // DELAYED AT 50% POINT ----------------------------------------------------------
            45% { left: 27%; }           /* Hold at the center */
            55% { left: 27%; }           /* Accelerate back towards the right */
            // DELAYED AT 50% POINT ----------------------------------------------------------
            100% { left: -300%; }         /* Move off-screen to the right */
        }

        @keyframes bird {
            0% { left: 50%; top: -30%; transform: scale(0.1); opacity: .8;}
            100% { left: 200%; top: 200%; transform: scale(1.5); opacity: .9;}
        }
    }

    &.about {
        background-color: $about;
        .comment {color:$pale-blue!important}
        h1 {color:$white}
        p, li {color:$white;}
        li {margin-bottom: 0}
        .icons {
            width: 100%;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 180px;
            div {
                display: inline-block;
                p {
                    margin-top:5px;
                    font-size:.62rem;
                    line-height: 1.4rem;
                    span {color:$yellow}
                }
            }
        }
    }

    &.approach {
        background-color: $approach;
        h1 {color:$white; margin-bottom: 30px}
        hr {
            border-style: dashed;
            border-color: $white;
            opacity: 0.3;
        }
        .row:last-child {margin-bottom:50px;}
        .row {
            margin-top: 30px;
            p {color: $white;}
            img {width: 115px;}
        }
    }

    &.projects {
        .projects-header {
            width: 100% !important;
            display: inline-block;
            text-align: center;
            nav {
                > a {
                    margin:0 1.5rem .75rem 1.5rem;
                    padding: 0;
                    cursor:pointer;
                    color:$midGrey!important;
                    @include font(300, .75rem, 'latoregular');
                    text-transform: uppercase;
                    border-bottom: 2px solid transparent;
                }
                > a:hover {
                    border-bottom: 2px solid $red;
                    text-decoration: none;
                }
                .row {
                    .col-lg-3 {
                        @include transition(all 0.5s ease-in);
                        padding:0;
                    }
                    margin-right: 0;
                    margin-left: 0;
                    img {width: 100%; @include transition(all 0.2s ease-in);}
                    .bbc-is-active {
                        a {padding-top:50%!important}
                    }
                }

                figure:hover,
                a.umg:hover ~ .row div .umg-is-active,
                a.turner:hover ~ .row div .turner-is-active,
                a.bbc:hover ~ .row div .bbc-is-active,
                a.monum:hover ~ .row div .monum-is-active,
                a.setanta:hover ~ .row div .setanta-is-active,
                a.ibm:hover ~ .row div .ibm-is-active,
                a.aa:hover ~ .row div .aa-is-active {
                    img {
                        width: 105%;
                        margin: -3.8% auto auto -2%;
                    }
                    img.tall {
                        margin: -7.6% auto auto -2%;
                    }
                    .tint {
                        opacity: 0.8;
                        @include transition(all 0.2s ease-in);
                    }
                    a {opacity:1}
                }
            }
        }
    }

    &.contact {
        background-color: $yellow;
        text-align: center;
        h3 {color:$white}
        img:not(.loading) {
            width: 155px;
        }
        ul.contact-panel {
            margin:40px 0 0 0;
            li {
                display:inline-block;
                background-position: center left!important;
                background-size: 25px!important;
                background-repeat: no-repeat!important;
                padding:5px 30px;
                a {color: $black}
            }
            li:nth-child(1) {background: url(../images/icons/icon-phone.svg)}
            li:nth-child(2) {background: url(../images/icons/icon-location.svg)}
            li:nth-child(3) {background: url(../images/icons/icon-email.svg)}
        }
    }
}

// --------------------------------------------------------------------------------

.App {
    padding:0;
    .is-sticky {
        .sub-nav {
            position:fixed;
            top:50px;
            z-index:0;
            opacity: 0.95;
        }
    }
    // lists
    ul {
        margin: 0;
        padding: 0 0 0 16px;
        li {
            margin: 0 0 5px 0;
            padding: 0;
        }
    }

    // tables
    table {
        th {
            white-space: nowrap;
            color: $tableHead;
            font-weight: 300;
            font-size: 0.9rem;
            padding: 0 40px 5px 0;
        }
        th, td {
            text-align: left;
        }
        tbody {
            td {
                vertical-align: top;
                padding: 0 40px 20px 0;
                color:$darkGrey;
                li {
                    margin: 0;
                }
            }
        }
    }
}