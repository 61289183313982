.sign-in,
.register,
.not-found {
    padding: 17.5rem 0 10rem;
    background: url("../images/home/arch.png") no-repeat center 4rem;
    background-size: 33rem;

    a {
        margin-right: 0;
    }

    h1 {
        font-size: 4rem;
        padding-top: 0;
    }

    form {
        max-width: 13rem;
    }
}


.sign-out-panel {
    position: absolute;
    right: 10.5rem;
    top: 0;

    a {
        padding: .15rem 1rem;
        margin: .5rem 0 0 0;
        color: $red!important;
        text-align: center;
    }
}

.bye {
    p {
        text-align: center;
    }
}

.hide-progress {
    display: none;
}

.show-progress {
    display: block;
    position: absolute;
    width: 15rem;
    border-bottom: 4rem white solid;
    margin-top: 4rem;
}