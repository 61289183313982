// Lato

@font-face {
    font-family: 'latothin';
    src: url('../fonts/lato-thin-webfont.eot') format('eot');
    src: url('../fonts/lato-thin-webfont.svg') format('svg');
    src: url('../fonts/lato-thin-webfont.ttf') format('ttf');
    src: url('../fonts/lato-thin-webfont.woff') format('woff');
    src: url('../fonts/lato-thin-webfont.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'latolight';
    src: url('../fonts/lato-light-webfont.eot') format('eot');
    src: url('../fonts/lato-light-webfont.svg') format('svg');
    src: url('../fonts/lato-light-webfont.ttf') format('ttf');
    src: url('../fonts/lato-light-webfont.woff') format('woff');
    src: url('../fonts/lato-light-webfont.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.eot') format('eot');
    src: url('../fonts/lato-regular-webfont.svg') format('svg');
    src: url('../fonts/lato-regular-webfont.ttf') format('ttf');
    src: url('../fonts/lato-regular-webfont.woff') format('woff');
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold-webfont.eot') format('eot');
    src: url('../fonts/lato-bold-webfont.svg') format('svg');
    src: url('../fonts/lato-bold-webfont.ttf') format('ttf');
    src: url('../fonts/lato-bold-webfont.woff') format('woff');
    src: url('../fonts/lato-bold-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black-webfont.eot') format('eot');
    src: url('../fonts/lato-black-webfont.svg') format('svg');
    src: url('../fonts/lato-black-webfont.ttf') format('ttf');
    src: url('../fonts/lato-black-webfont.woff') format('woff');
    src: url('../fonts/lato-black-webfont.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

// italic

@font-face {
    font-family: 'latothinitalic';
    src: url('../fonts/lato-thinitalic-webfont.eot') format('eot');
    src: url('../fonts/lato-thinitalic-webfont.svg') format('svg');
    src: url('../fonts/lato-thinitalic-webfont.ttf') format('ttf');
    src: url('../fonts/lato-thinitalic-webfont.woff') format('woff');
    src: url('../fonts/lato-thinitalic-webfont.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'latolightitalic';
    src: url('../fonts/lato-lightitalic-webfont.eot') format('eot');
    src: url('../fonts/lato-lightitalic-webfont.svg') format('svg');
    src: url('../fonts/lato-lightitalic-webfont.ttf') format('ttf');
    src: url('../fonts/lato-lightitalic-webfont.woff') format('woff');
    src: url('../fonts/lato-lightitalic-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'latoregularitalic';
    src: url('../fonts/lato-regularitalic-webfont.eot') format('eot');
    src: url('../fonts/lato-regularitalic-webfont.svg') format('svg');
    src: url('../fonts/lato-regularitalic-webfont.ttf') format('ttf');
    src: url('../fonts/lato-regularitalic-webfont.woff') format('woff');
    src: url('../fonts/lato-regularitalic-webfont.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'latobolditalic';
    src: url('../fonts/lato-bolditalic-webfont.eot') format('eot');
    src: url('../fonts/lato-bolditalic-webfont.svg') format('svg');
    src: url('../fonts/lato-bolditalic-webfont.ttf') format('ttf');
    src: url('../fonts/lato-bolditalic-webfont.woff') format('woff');
    src: url('../fonts/lato-bolditalic-webfont.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'latoblackitalic';
    src: url('../fonts/lato-blackitalic-webfont.eot') format('eot');
    src: url('../fonts/lato-blackitalic-webfont.svg') format('svg');
    src: url('../fonts/lato-blackitalic-webfont.ttf') format('ttf');
    src: url('../fonts/lato-blackitalic-webfont.woff') format('woff');
    src: url('../fonts/lato-blackitalic-webfont.woff2') format('woff2');
    font-weight: 1000;
    font-style: normal;
}

// Oraqle Script

@font-face {
    font-family: 'oraqlescript';
    src: url('../fonts/OraqleScript.otf') format('otf');
    src: url('../fonts/OraqleScript.ttf') format('ttf');
    src: url('../fonts/OraqleScript.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@mixin font($weight:$weight, $size:$size, $font-family:$default-font){
    font: $weight $size $font-family;
}

$default-font: 'latoregular'; 

@font-face {
    font-family: "material-icons";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/material-design-icons/MaterialIcons-Regular.ttf") format("truetype");
  }