html {
    body.projects {
        background-size: 100%;

        .transpose-menu {
            .mini-menu {
                position: fixed;
                padding-top: 3.75rem;
                background-color: rgba(255, 255, 255, .8);
                z-index: 100;
            }
        }
        .mini-menu {
            position: absolute;
            width: 2.25rem;
            z-index: 9999999;
            right: 0;
            top: 0;
            background-color: rgba(255, 255, 255, .05);
            border: 1px dashed $darkGrey;
            border-top: none;
            padding: 26rem .5rem .25rem .5rem;
            border-radius: 0 0 0 1rem;

            a {
                display: block;
                white-space: nowrap;
                margin: 0 0 .5rem 0;
                &.active,
                &:hover {
                    .material-icons {
                        color: $green;
                    }
                }
                .material-icons {
                    font-size: 1.25rem!important;
                    white-space: nowrap;
                }
                .menu-name {
                    opacity: 0;
                    position: absolute;
                    right: calc(100% - .25rem);
                    font-size: .7rem;
                    color: $darkGrey;
                    margin-top: .1rem;
                    background-color: rgba(255, 255, 255, .8);
                    padding: .025rem .75rem;
                    border-radius: .3rem;
                    border: 1px dashed $midGrey;
                    transition: opacity .5s ease-out
                }
                &:hover {
                    .menu-name {
                        opacity: 1;
                    }
                }
            }
        }
        .App {
            overflow: hidden;
            /* Links */
            a {color: $red; text-decoration: none}
            a:hover {color:#252525; text-decoration: none}
            /* Lists */
            ul li { 
                margin: 0;

                a {
                    padding: .25rem 0;
                }
            }
        
            /* Text */
            h1 {font-family: 'latobold'; font-size: 1.5rem; text-transform: uppercase; letter-spacing: .4rem; color:#ff0049}
            h2 {font-family: 'latolight'; margin:0; color:$h2; padding: 5rem 0 1rem 0;}
            h3 {font-family: 'latolight'; margin: 0; font-size: 2.25rem; padding: 5rem 0 .5rem 0;}
            p label {width:100%; color:$red; margin-bottom: 0; font-size: .95rem; letter-spacing: .05rem;}
        
            /* Layout */
            img.to-the-right-edge {float:right; margin-right:-90px}
            .row {
                margin-right: 0;
                margin-left: 0;
            }
            .container-fluid {
                background:$white; 
                position: relative;
            }
            .container-fluid.fullwidth {
                padding-right:0!important;
                padding-left:0!important;
                .row {
                    margin-right:0;
                    margin-left:0;
                    padding-right:40px;
                    padding-left:40px;
                }
            }
            h4 {
                color:$black;
                @include font(600, 0.9rem); 
                margin: 0 0 6px 0;
                text-transform: uppercase;
                letter-spacing: .2rem;
            }
          
            .banner {
                background-color: $jetBlack;
                position:fixed;
                left:0;
                right:0;
                top:0;
                h4 {color:$white; font-size: 1rem; font-family:'latolight'}
                h3 {
                    text-align: left; 
                    margin: 0 0 3px 0; 
                    padding: 0 0 3px 0; 
                    border-bottom:1px dashed #666; 
                    width:100%; 
                    float:left;
                    text-transform: uppercase;
                    color:white;
                    font-family: 'latolight';
                    letter-spacing: .3rem;
                    font-size:.8rem;
                    font-weight: bolder;
                }
                img {
                    max-width: 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    z-index: -1;
                }
                //----------------
                &.aa-active {
                    img {
                        opacity: .2;
                        left: 0;
                        width: 100%;
                    }
                    nav.projects-menu ul {
                        li:nth-child(1) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.umg-active {
                    nav.projects-menu ul {
                        li:nth-child(2) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.turner-active {
                    nav.projects-menu ul {
                        li:nth-child(3) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.bbc-active {
                    nav.projects-menu ul {
                        li:nth-child(4) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.monum-active {
                    nav.projects-menu ul {
                        li:nth-child(5) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.setanta-active {
                    nav.projects-menu ul {
                        li:nth-child(6) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.ibm-active {
                    nav.projects-menu ul {
                        li:nth-child(7) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                &.private-active {
                    nav.projects-menu ul {
                        li:nth-child(8) a {
                            color:$white;
                            i {display: block;}
                        }
                    }
                }
                //---------------
                nav.downloads-menu {
                    position: absolute;
                    top: 5rem;
                    right:60px;
                    z-index: 1;
                    ul {
                        margin: 0;
                        padding: 0;
                        line-height: 1.2rem;
                        li {
                            display: block;
                            margin:0 0 2px 0;
                            text-align: right;
                            a:hover {color:$white!important}
                            a {
                                color:$red!important;
                                font-family: 'latoregular';
                                letter-spacing: .05rem;
                                font-size: .65rem;
                            }
                            a.pdf {
                                background: url(../images/icons/pdf.png) no-repeat left center;
                                background-size: 13px;
                                padding: 3px 0 3px 19px;
                            }
                        }
                    }
                }
                nav.projects-menu {
                    position: absolute;
                    z-index: 1;
                    ul {
                        margin:0;
                        padding:0;
                        line-height: 1.4rem;
                        li {
                            font-size: .65rem;
                            position: relative;
                            a:hover {color:$white}
                            a {
                                color:#888;
                                font-family: 'latoregular';
                                letter-spacing: .05rem;
                                i {
                                    display: none;
                                    width:7px;
                                    height:7px;
                                    border-top:1px solid $white;
                                    border-right:1px solid $white;
                                    transform: rotate(45deg);
                                    position: absolute;
                                    left: -14px;
                                    top: 7px;
                                }
                            }
                        }
                    }
                }
                /* Projects Headers */
                .projects-header {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    bottom: 25px;
                    left: 0;
                    right: 0;
                    h1 {color:white; line-height: 2rem;}
                    p {
                        text-transform: uppercase; 
                        font-size: .6rem; 
                        text-align: left; 
                        line-height: 1.1rem; 
                        margin:0; 
                        padding:0;
                        color:#999;
                        letter-spacing: .2rem;
                        label {float:left; clear:left; width:100%; text-transform: uppercase; color:#ff0049}
                    }
                    ul {
                        margin:0 60px; 
                        padding: 0; 
                        float:none;
                        li {display:block; margin: 0; padding: 0; width: 25%; float:left}
                    }
                } // end projects header
            }
        } // end App
    } // end projects
} // end html

/* ------------------------------------------------- Project Specific ------------------------------------------------ */

html {
    // UMG
    body.umg {
        .App {
            img.pdf { width: 12px; margin: -3px 5px 0 0 }
            .row {
                .red-panel {
                    background-color: $red;
                    padding-top: 20px;
                    color:$white;
                    h4 {color:$white}
                }
            }
            .container-fluid {
                background:$white url('../images/clients/pages/umg/background.png') no-repeat 0 0;
                background-size:100%;
            }
        }
    }

    // Turner
    body.turner {
        .App {
            img.personas {width:100%; margin-top:-160px}
            .row {
                .red-panel {
                    background-color: $red; 
                    color:$white;
                    label {color:$white}
                }
            }
            .container-fluid {
                background-size:100%;
                img.batman {
                    width:170%;
                    margin-top:-96px;
                    vertical-align: middle;
                    border-style: none;
                    position: absolute;
                    z-index: 1;
                }
                .row.carousel {
                    overflow: hidden;
                    max-height: 260px;
                    border-top: 1px dashed $yellow;
                    background: #fcfcff;
                    img {
                        width: 100%;
                    }
                }
                .row.ctn-ntw {
                    padding-top:80px;
                    background-color:$crimson;
                    img.adv-time {
                        position: absolute;
                    }
                }
                .row.scooby {
                    min-height: 400px;
                    margin-top: 123px;
                    padding-top:100px;
                    p, h4 {color:$white}
                    h4 {line-height:24px}
                    img.scooby-van {
                        position: absolute;
                        left: -40px;
                    }
                }
                .row.as {
                    padding-top:80px;
                    background-color:$crimson;
                }
            }
        }
    }

    // BBC
    body.bbc {
        .App {
            .container-fluid {
                background:$white url('../images/clients/pages/bbc/tyre-marks-grey.png') no-repeat;
                background-position: top right -322px;
                background-size: 1200px;

                .row.youtube {
                    position: relative;
                    ul {
                        position: absolute;
                        top: 82%;
                        left: 34%;
                        width: 100%;
                        li {
                            display: inline-block;
                            width: 20%;
                            margin-right: 10%;
                            p, h4 {color:$white}
                        }
                    }
                }
                .row.topgear-grouped {
                    margin-top:-100px;
                }
            }
        }
    }
} // end html
