body {
    &.home,
    &.view-post,
    &.edit-post {
        .top-section a {
            &:nth-child(1) {
                border-bottom: 2px solid $red;
                color: $black!important;
            }
        }
    }
    &.add-post {
        .top-section a {
            &:nth-child(2) {
                border-bottom: 2px solid $red;
                color: $black!important;
            }
        }
    }
    &.users {
        .top-section a {
            &:nth-child(3) {
                border-bottom: 2px solid $red;
                color: $black!important;
            }
        }
    }
    &.blog {
        background-color: $yellow;
        background-size: 30rem;

        main {
            padding: 0 3.5rem 30rem;
            overflow-y: auto;
        }

        .top-section {
            position: fixed;
            background: $yellow;
            left: 0;
            right: 0;
            top: 2.75rem;
            padding: 2rem 3.5rem 0;
            box-shadow: 0.5rem 0 1rem #6e0d1a30;
            border-bottom: 2px dotted $white;

            nav {
                margin-bottom: -.125rem;
            }

            a {
                font-size: .75rem;
                margin-right: 2rem;
                letter-spacing: .1rem;
                height: 1.75rem;
                text-transform: uppercase;
                color: $red;
            }
        }

        h1 {
            font-family: 'latoregular';
            text-transform: uppercase;
            font-size: 3.5rem;
            text-align: left;
            color: $white;
            margin: 0 0 1rem -.25rem;
            padding: 0;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
        }
        h2 {
            color: $navy
        }
        pre {
            line-height: 1.5rem;
            font-size: .8rem;
        }
        article {
            padding: 2rem 0;
            border-bottom: 1px dashed $navy;

            &.no-border {
                border-bottom: none;
            }
        }
        .reaction-btn {
            background-color: transparent;
            border: none;
        }
        .post-credit {
            margin-bottom: .25rem;
        }

        footer {
            position: fixed;
            padding: 3rem 3.5rem 0;
            background-color: $black;
            bottom: 0;
            left: 0;
            right: 0;
            .house {
                width: 10rem;
                background: url('../images/blog/house.png') no-repeat center bottom;
                background-size: 12rem;
                h3 {
                    color: $white;
                    text-transform: uppercase;
                }
                a {
                    color: $yellow;
                    font-size: .45rem;
                }

                p {
                    color: $white;
                    font-size: .45rem;
                    line-height: 1rem;
                    margin-bottom: .5rem;
                    width: 10rem;
                    text-transform: uppercase;
                }
            }
            .tree {
                background: url('../images/blog/tree.png') no-repeat center bottom;
                background-size: 100%;
                opacity: .9;
            }
            .bench {
                width: 6rem;
                background: url('../images/blog/bench.png') no-repeat center bottom;
                background-size: 100%;
                margin-left: -9rem
            }
            .grass {
                background: url('../images/blog/grass.png') no-repeat center bottom -.5rem;
                background-size: 100%;
                margin-right: 0;
            }
            .tunnel {
                width: 35rem;
                background: url('../images/blog/tunnel.png') no-repeat center bottom;
                background-size: 100%;
                margin-left: -6rem;
                margin-right: -3rem
            }
        }
    }
}