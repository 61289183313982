﻿@import "variables";

svg.demo {
    //height: 176px;
    //width: 1200px;
    //margin: 0 0 0 70px;
    //transform: translateX(-200px);
    .target-path {
        fill: none;
        stroke: $black;
        stroke-width: 1;
        stroke-linecap: square;
        stroke-miterlimit: 10;
    }
}

.horizontal-pan {
    div {
        position: absolute;
        div {position: static;}
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-32px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes zoomFadeIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes swing {
    15%
    {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30%
    {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
    50%
    {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65%
    {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80%
    {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100%
    {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}