.field {
    position: relative;
}

input:not(input[type=checkbox]),
textarea {
    border: 1px solid $midGrey;
    margin-bottom: 1rem!important;
    width: 100%;
}

input[type=checkbox] {
    margin-right: .25rem;
}

select {
    padding: .35rem .25rem;
}

button:not(.reaction-btn) {
    border: none;
    border-radius: .25rem!important;
    background-color: $red;
    color: $white;
    padding: .25rem 1rem;
    margin-bottom: .5rem!important;
    width: 100%;

    &:hover {
        background-color: $links;
    }

    &.edit-btn {
        background-color: $green;
    }
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    width: 20rem;

    &.contact-form {
        position: relative;
        .err-msg {
            color: $red;
            position: absolute;
            top: -2rem;
            width: 100%;
            text-align: center;
        }
        .required {
            font-size: 1.5rem;
            color: $red;
        }
        input, textarea {
            background-color: $white!important;
            border-color: $red;
        }
        .btn-reset {
            background-color: $black!important;
        }
    }
}

.blog {
    form {
        max-width: 50rem;
        width: auto;
    }
}

a, a:visited {
    color: #fff;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea {
  padding: .15rem .5rem;
  border-radius: .25rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    color: $white;
    background-color: $black;
    padding: 0.25rem;
    position: absolute;
    top: 100%;
    z-index: 1;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    display: none;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: $red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: $red;
    font-weight: bold;
    padding: 0 0.5rem;
    margin: 1rem 0 0 0;
    border-radius: 0.25rem;
}

.line {
    display: inline-block;
}

.loading {
    position: absolute;
    top: .5rem;
    right: 1rem;
    width: 1rem;
    height: 1rem
}
