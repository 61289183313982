﻿.main-header {
    position: fixed;
    background-color: $white;
    top:0;
    left:0;
    z-index: 999;
    height: 44px;
    width: 100%!important;

    .logo {
        background: url('../images/pixelheads-logo.svg') no-repeat top center;
        background-size: 100%;
        width: 6.5rem;
        height: 2rem;
    }

    .toggle-music {
        background-color: transparent;
        padding: 0;
        margin: 0.25rem 1rem 1rem 0;
        font-size: 1rem;
        color: $midGrey;
        width: 1.25rem;
        height: 1.25rem;
        &.playing {
            color: $audio;
        }
        &:hover {
            background-color: transparent;
        }
    }

    .visitor-count {
        color: $red;
        font-style: italic;
        margin: 0 1rem 0 0;
    }

    .device {
        display: none;
        font-size: 1rem;
        margin: 0 1rem 0 -.15rem;
    }

    a {
        @include font(300, .63rem, 'latobold');
        text-transform: uppercase;
    }

    .right-section {
        a {
            &:hover {
                color: $links;
            }
        }
    }
    nav.main-nav {
        white-space: nowrap;
        a {
            margin: 0 2rem 0 0;
            padding: 0;
            cursor:pointer;
            color:$black;
            opacity:0.6;
            //border-bottom: 2px solid $white;
        }
        a:hover {text-decoration: none!important; color: $red!important}
        a.active {
            opacity:1;
            border-bottom: 2px solid $red;
        }
        a.back-link {
            color: #ffe007!important;
            opacity: 1;
            position: relative;

            i {
                width: 7px;
                height: 7px;
                border-left: 2px solid #ffe007;
                border-bottom: 2px solid #ffe007;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                left: -14px;
                top: .15rem;
            }
        }
    }
    .main-nav-small.show-menu {
        nav {top:0} 
        .tint {opacity: 0.8; visibility: visible}
        .menu-icon div {background-color:$black}
    }
    .main-nav-small {
        .menu-icon {
            z-index: 2;
            width: 22px;
            display: block;
            position: absolute;
            right: 0;
            top: .75rem;
            div {
                width: 100%;
                height: 3px;
                background-color: $red;
                border-radius: 30px;
                margin: 0 0 4px 0;
            }
        }
        .tint {
            background-color: $jetBlack;
            @include transition(all 0.2s ease-in);
            opacity: 0;
            visibility: hidden;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
        }
        nav {
            top: -245px;
            @include transition(all 0.2s ease-in);
            background: white;
            z-index: 1;
            position: fixed;
            width: 100%;
            height: 243px;
            left: 0;
            padding-top: 10px;
            a.active {color:$red!important}
            a:last-child {border-bottom:none}
            a {
                width: 100%;
                height: auto;
                cursor: pointer;
                text-align: center;
                display: block;
                margin: 0;
                padding: 16px 0;
                cursor:pointer;
                color:$black!important;
                opacity:0.6;
                @include font(300, .7rem, 'latobold');
                text-transform: uppercase;
                border-bottom: 1px dashed $lightGrey;
            }
            a:hover {text-decoration: none!important}
            a.active {
                opacity:1;
            }
        }
    }
}

// projects page only
body.projects {
    .main-header {
        background-color: $jetBlack;
        .logo {
            background:$jetBlack url('../images/pixelheads-logo-inverted.svg') no-repeat;
            margin-right: 1rem;
        }
        opacity: 1;
        .right-section {
            a {
                .material-icons {
                    color: $white!important;
                    opacity: .8;
                }
                &:hover {
                    color: $white;
                }
            }
        }
        .jump-to-top {
            max-width: 3rem;
            position: absolute;
            left: calc(50% - 1.5rem);
            top: 0;

            a.top {
                opacity: 0;
                @include transition(all 0.5s ease-in);
                color: $black;
                width: 50px;
                height: 50px;
                margin: -50px auto 0 auto;
                display: inline-block;
                text-align: center;
                background: $black;
                transform: rotate(45deg);
                @include drop-shadow(0px, 1px, 4px, 0.1);
                div.top-arrow {
                    @include transition(all 0.2s ease-in);
                    display: block;
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    width: 10px;
                    height: 10px;
                    border-left: 1px solid $red;
                    border-top: 1px solid $red;
                }
                div.bottom-arrow {
                    opacity: 0;
                    @include transition(all 0.2s ease-in);
                    display: block;
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    width: 10px;
                    height: 10px;
                    border-left: 1px solid $red;
                    border-top: 1px solid $red;
                }
                label {
                    position: absolute;
                    top: 24px;
                    left: 21px;
                    display: block;
                    transform: rotate(-45deg);
                    text-transform: uppercase;
                    font-size: .5rem;
                    color: $black;
                    cursor: pointer;
                }
            }
        }

        nav.main-nav {
            a {
                color:$white;
                @include font(300, .63rem, 'latoregular');
                height: auto;
                margin: .25rem 0 0 0;

                span:not(.material-icons) {
                    &:hover {
                        text-decoration: underline!important
                    }
                }
            }
        }
    }
    // hover
    .transpose-menu {
        .main-header {
            @include drop-shadow(0px, 1px, 4px, 0.1);
            @include transition(background-color 0.5s ease-in);
            background-color: $white;
            opacity: 0.95;

            .logo {
                background: url('../images/pixelheads-logo.svg') no-repeat;
                margin-right: 1rem;
            }

            .right-section {
                a {
                    .material-icons {
                        color: $black!important;
                        opacity: .8;
                    }
                    &:hover {
                        color: $links;
                    }
                }
            }

            nav.main-nav {
                a {
                    color:$black!important;
                    @include font(300, .63rem, 'latobold');
                }
            }

            a.top {
                opacity: 1;
                margin: 19px auto 0 auto;
                background: $white;
                div {
                    @include transition(all 0.5s ease-in);
                }
                &:hover {
                    div.top-arrow  {
                        @include transition( all 0.2s ease-in);
                        top: 11px;
                        left: 11px;
                        width: 15px; 
                        height: 15px;
                    }
                    div.bottom-arrow  {
                        @include transition(all 0.2s ease-in);
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

// home page only
.home-page {
    .main-header {
        nav.main-nav {
            a:nth-child(1) {animation-duration: .3s;}
            a:nth-child(2) {animation-duration: .5s;}
            a:nth-child(3) {animation-duration: .7s;}
            a:nth-child(4) {animation-duration: .9s;}
            a:nth-child(5) {animation-duration: 1.1s;}
            a {
                // uses slideInFromTop from animation.scss
                animation-timing-function: ease-in; /* how the animation will behave */
                animation-delay: 0s; /* how long to delay the animation from starting */
                animation-iteration-count: 1; /* how many times the animation will play */
                animation-name: slideInFromTop; /* the name of the animation we defined above */
            }
        }
    }
}